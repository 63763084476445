import React, { useState, Fragment } from "react"
import { PropTypes } from "prop-types"
import { MdArrowBack } from "react-icons/md"
import { LinkButton } from "gatsby-interface"
import { PageContent } from "@modules/layout/components/Page"
import { BillingInterval, MachinePricingTier } from "@modules/graphql/types"
import { UsagePlanType } from "@modules/enums/constants"
import { ErrorModal } from "@modules/modal"
import { billing as text } from "@modules/locales/default.js"
import PaymentDots from "./PaymentDots"
import Loading from "@modules/ui/components/Loading"
import { PlanInformation } from "../shared/components/PlanInformation"
import RequestForm from "@modules/billing/components/RequestForm"
import Subscribe from "@modules/billing/components/Subscribe"
import {
  useFilteredPlans,
  useAllPlans,
  isBillingChange,
} from "./Billing.helpers"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { isTrialingPlan, isFreePlan, normalizePlanTier } from "../shared/utils"
import { PlanSelector } from "../shared/components/PlanSelector"
import { supportedPlansOrder } from "../constants"
import { isProduction } from "@modules/env/constants"
import { MultiPlanSelector } from "@modules/billing/shared/components/MultiPlanSelector"
import { useFlags } from "@modules/featureFlags"

export function BillingForm({
  billing,
  organizationId,
  organizationName,
  user,
}) {
  const { flags } = useFlags()
  const [planInfo, setPlanInfo] = React.useState({
    planId: `asdfadsfadsfasdf`,
    planName: null,
    billingInterval: BillingInterval.Annual,
    buildsTier: billing.plan.buildsTier,
    hostingTier: billing.plan.hostingTier,
  })

  const isTrialing = isTrialingPlan(billing.status)
  const isFree = isFreePlan(billing.status)

  const scrollTarget = React.useRef(null)
  const [interval, setInterval] = useState(BillingInterval.Monthly)
  const [selectedPlanTier, setSelectedPlanTier] = React.useState(null)
  const currentPlanName = billing.plan.name || null

  const filteredPlans = useFilteredPlans({
    planName: !isTrialing ? currentPlanName : undefined,
    organizationId,
    interval,
    skip: flags.multiTiersPlans,
  })

  const allPlans = useAllPlans({
    organizationId,
    skip: !flags.multiTiersPlans,
  })

  if (filteredPlans.error || allPlans.error) {
    return (
      <ErrorModal
        errMsg={
          flags.multiTiersPlans
            ? allPlans.error.message
            : filteredPlans.error.message
        }
      />
    )
  }

  if (filteredPlans.loading || allPlans.loading) {
    return <Loading delay={1000} message="loading plan & payment details..." />
  }

  const planByTier = filteredPlans.plans.reduce((memo, plan) => {
    const tier = normalizePlanTier(plan.name)

    if (!tier) {
      if (!isProduction) {
        console.error(
          `Could not find a matching plan tier for plan with name "${plan.name}"`
        )
      }
      return memo
    }

    return {
      ...memo,
      [tier]: plan,
    }
  }, {})

  let plan = null
  let billingFormVisible = false
  let requestFormVisible = false

  if (flags.multiTiersPlans) {
    const diyOptions = [
      MachinePricingTier.Standard,
      MachinePricingTier.Performance,
    ]

    plan = allPlans?.plans?.find(plan => plan.id === planInfo.planId)

    billingFormVisible =
      (isTrialing || isFree) &&
      (diyOptions.includes(planInfo.buildsTier) ||
        diyOptions.includes(planInfo.hostingTier)) &&
      plan

    requestFormVisible =
      !billingFormVisible && isBillingChange(planInfo, billing)
  } else {
    plan = planByTier[selectedPlanTier]

    billingFormVisible =
      (isFreePlan(billing.status) || isTrialing) &&
      plan &&
      [
        UsagePlanType.Business,
        UsagePlanType.Professional,
        UsagePlanType.Individual,
        UsagePlanType.Team,
      ].includes(plan.name)

    requestFormVisible = plan && !billingFormVisible
  }

  const availableTiersOrder = supportedPlansOrder.filter(tier =>
    Boolean(planByTier[tier])
  )

  return (
    <PageContent.Positioner>
      <div
        css={{
          maxWidth: flags.multiTiersPlans ? `80rem` : `64rem`,
          margin: `0 auto`,
        }}
      >
        <PlanInformation
          organizationId={organizationId}
          permissionToCreate={false}
          onBillingPage={true}
          css={{ margin: `0 auto 3rem` }}
        />
        {flags.multiTiersPlans ? (
          <MultiPlanSelector
            availablePlans={allPlans.plans}
            planInfo={planInfo}
            setPlanInfo={setPlanInfo}
            enterpriseSelectable={true}
          />
        ) : (
          <PlanSelector
            planOptions={availableTiersOrder}
            selectedPlan={selectedPlanTier}
            onSelectPlan={tier => {
              setSelectedPlanTier(tier)
              setTimeout(() => {
                if (scrollTarget.current) {
                  window.scrollTo({
                    top: scrollTarget.current.offsetTop,

                    behavior: `smooth`,
                  })
                }
              }, 150)
            }}
            selectedInterval={interval}
            onSelectInterval={setInterval}
            getPlanButtonLabel={tier => `Change to ${tier}`}
          />
        )}
        <div ref={scrollTarget} />

        {billingFormVisible && (
          <div
            css={{
              maxWidth: `50rem`,
              margin: `0 auto`,
            }}
          >
            <Subscribe
              organizationId={organizationId}
              plan={plan}
              name={organizationName}
            />
          </div>
        )}

        {requestFormVisible && (
          <Fragment>
            <PaymentDots
              css={{
                position: `absolute`,
                bottom: 0,
                left: 0,
              }}
            />

            <RequestForm
              user={user}
              changeTo={
                flags.multiTiersPlans ? billing.plan.name : selectedPlanTier
              }
              cancel={() => {
                setSelectedPlanTier(null)
              }}
              organizationId={organizationId}
              currentPlanInfo={billing.plan}
              nextPlanInfo={planInfo}
              isTrialing={isTrialing}
            />
          </Fragment>
        )}

        {!plan && (
          <LinkButton
            to={getPathToOrgSettings(organizationId)}
            variant="SECONDARY"
            leftIcon={<MdArrowBack />}
            css={theme => ({
              marginTop: theme.space[7],
            })}
          >
            {text.cancel}
          </LinkButton>
        )}
      </div>
    </PageContent.Positioner>
  )
}

BillingForm.propTypes = {
  organizationId: PropTypes.string,
}
