import * as React from "react"
import {
  useChangePlanMutation,
  useCancelPlanMutation,
} from "@modules/billing/queries.generated"
import { useMutation } from "react-apollo"
import { REQUEST_PLAN_CHANGE } from "@modules/billing/queries"
import usePlanChangeState from "@modules/billing/shared/hooks/usePlanChangeState"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { navigate } from "gatsby"
import {
  PlanTier,
  HandlePlanChangeInput,
} from "@modules/billing/components/RequestForm/types"

export const isTierDowngrade = (
  currentTier: PlanTier,
  nextTier: PlanTier
): boolean => {
  let downgrade = false

  switch (currentTier) {
    case `ENTERPRISE`:
      if (nextTier !== `ENTERPRISE`) {
        downgrade = true
      }
      break
    case `PERFORMANCE`:
      if (nextTier !== `PERFORMANCE` && nextTier !== `ENTERPRISE`) {
        downgrade = true
      }
      break
    case `STANDARD`:
      if (nextTier === `FREE`) {
        downgrade = true
      }
      break
    default:
      downgrade = false
  }

  return downgrade
}

export const isDowngrade = ({
  hostingTier,
  nextHostingTier,
  buildsTier,
  nextBuildsTier,
}: {
  hostingTier: PlanTier
  nextHostingTier: PlanTier
  buildsTier: PlanTier
  nextBuildsTier: PlanTier
}): boolean => {
  const hostingDowngrade = isTierDowngrade(hostingTier, nextHostingTier)
  const buildsDowngrade = isTierDowngrade(buildsTier, nextBuildsTier)

  if (hostingDowngrade || buildsDowngrade) {
    return true
  }

  return false
}

export const isEnterpriseUpgrade = ({
  hostingTier,
  nextHostingTier,
  buildsTier,
  nextBuildsTier,
}: {
  hostingTier: PlanTier
  nextHostingTier: PlanTier
  buildsTier: PlanTier
  nextBuildsTier: PlanTier
}): boolean => {
  const enterpriseHosting =
    hostingTier !== "ENTERPRISE" && nextHostingTier === "ENTERPRISE"
  const enterpriseBuilds =
    buildsTier !== "ENTERPRISE" && nextBuildsTier === "ENTERPRISE"

  if (enterpriseHosting || enterpriseBuilds) {
    return true
  }
  return false
}

export function useHandleSubmit<T>(
  {
    organizationId,
    currentPlan,
    hostingTier,
    buildsTier,
    billingInterval,
    nextPlanInfo,
    formValues,
  }: HandlePlanChangeInput,
  setError: React.Dispatch<React.SetStateAction<T | null>>
) {
  const [changePlan] = useChangePlanMutation()
  const [cancelPlan] = useCancelPlanMutation()
  const [sendRequest] = useMutation(REQUEST_PLAN_CHANGE)
  const { storePlanChangeRequest } = usePlanChangeState(organizationId)

  const {
    planName: nextPlanName,
    hostingTier: nextHostingTier,
    buildsTier: nextBuildsTier,
    planId: nextPlanId,
    billingInterval: nextBillingInterval,
  } = nextPlanInfo

  const handler = async () => {
    try {
      if (nextPlanId === `free`) {
        await cancelPlan({ variables: { organizationId } })
      } else if (
        !isEnterpriseUpgrade({
          buildsTier,
          hostingTier,
          nextBuildsTier,
          nextHostingTier,
        })
      ) {
        await changePlan({
          variables: {
            organizationId,
            nextPlanId,
          },
        })
      }

      await sendRequest({
        variables: {
          request: {
            ...formValues,
            plan: buildsTier,
            currentPlan,
            buildsTier,
            hostingTier,
            billingInterval,
            nextBuildsTier,
            nextHostingTier,
            nextBillingInterval,
            organizationId,
          },
        },
      })

      storePlanChangeRequest(nextPlanName)
      navigate(getPathToOrgSettings(organizationId))
    } catch (err) {
      setError(err)
    }
  }

  return handler
}
