import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import * as Yup from "yup"
import { navigate } from "gatsby"
import { MdArrowForward } from "react-icons/md"
import Lede from "./Lede"
import RequestDetail from "./RequestDetail"
import TrialDowngradeWarning from "./TrialDowngradeWarning"
import ConfirmPlanChange from "./ConfirmPlanChange"
import { Heading, Button, InputConnectedField } from "gatsby-interface"
import { changePlanRequest as text } from "@modules/locales/default.js"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import Form from "@modules/form/components/Form"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import {
  isDowngrade,
  isEnterpriseUpgrade,
  useHandleSubmit,
} from "./RequestForm.helpers"

const Row = styled(`div`)(props => ({
  display: `flex`,
  justifyContent: `space-between`,
  margin: `${props.theme.space[5]} 0`,
  marginTop: props.theme.space[5],
}))

const downgradeOptions = [
  "Technical Issues",
  "Missing features",
  "Change in internal priorities or loss of budget",
  "Shutting down the company",
  "Other",
]

function RequestForm({
  user,
  organizationId,
  nextPlanInfo,
  isTrialing,
  currentPlanInfo,
}) {
  const [setError, errorAlert] = useTriggerErrorAlert()
  const [setSubmitting, setSetSubmitting] = useState(() => null)
  const [isOpen, setIsOpen] = useState(false)

  const {
    hostingTier,
    buildsTier,
    name: currentPlan,
    interval: billingInterval,
  } = currentPlanInfo

  const [formValues, setFormValues] = useState({
    firstName: user ? user.firstName : ``,
    lastName: user ? user.lastName : ``,
    email: user ? user.email : ``,
    downgradeReason: ``,
    reasonDetail: ``,
  })

  const handleSubmit = useHandleSubmit(
    {
      organizationId,
      currentPlan,
      hostingTier,
      buildsTier,
      billingInterval,
      nextPlanInfo,
      formValues,
    },
    setError
  )

  const {
    planName: nextPlanName,
    hostingTier: nextHostingTier,
    buildsTier: nextBuildsTier,
    billingInterval: nextBillingInterval,
  } = nextPlanInfo

  const nextPlanIsDowngrade = isDowngrade({
    hostingTier,
    nextHostingTier,
    buildsTier,
    nextBuildsTier,
  })
  const nextPlanIsEnterpise = isEnterpriseUpgrade({
    hostingTier,
    nextHostingTier,
    buildsTier,
    nextBuildsTier,
  })

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(`The field is required`),
    lastName: Yup.string().required(`The field is required`),
    email: Yup.string()
      .email(`Invalid email`)
      .required(`Required`),
    downgradeReason: Yup.string().oneOf(downgradeOptions),
  })

  // Only require the reason detail if someone is downgrading.
  if (nextPlanIsDowngrade) {
    validationSchema.reasonDetail = Yup.string().required("Field is required")
  }

  const shouldShowDetail =
    nextPlanIsEnterpise || (!isTrialing && nextPlanIsDowngrade)

  const heading = text[nextPlanName]
    ? text[nextPlanName].heading
    : `${text[`default`].heading} ${nextPlanName}`

  return (
    <div
      css={theme => ({
        maxWidth: `40rem`,
        margin: `${theme.space[9]} auto`,
      })}
    >
      <Fragment>
        <Heading>{heading}</Heading>
        {isTrialing && nextPlanName === "Free" ? (
          <TrialDowngradeWarning />
        ) : (
          <Lede nextPlanName={nextPlanName} />
        )}
      </Fragment>

      {(nextPlanIsEnterpise || !isTrialing) && (
        <Form
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            setIsOpen(true)
            setSetSubmitting(() => actions.setSubmitting)
            setFormValues({
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              downgradeReason: values.downgradeReason,
              reason: values.reasonDetail,
            })
          }}
        >
          {({ isSubmitting, values }) => (
            <Form.FormElement
              applySpacing
              css={theme => ({
                margin: `${theme.space[9]} 0`,
                label: {
                  fontSize: theme.fontSizes[2],
                },
              })}
            >
              <InputConnectedField
                label="First name"
                name="firstName"
                required
              />
              <InputConnectedField label="Last name" name="lastName" required />
              <InputConnectedField label="Email" name="email" required />
              {shouldShowDetail && (
                <RequestDetail
                  values={values}
                  downgradeOptions={downgradeOptions}
                  isDowngrade={nextPlanIsDowngrade}
                />
              )}
              {errorAlert}
              <Row>
                <Button
                  variant="SECONDARY"
                  tone="NEUTRAL"
                  onClick={() => navigate(getPathToOrgSettings(organizationId))}
                >
                  {text.cancel}
                </Button>
                <Button
                  type="submit"
                  loading={isSubmitting}
                  rightIcon={<MdArrowForward />}
                  loadingLabel="Confirming"
                >
                  {text.submit}
                </Button>
              </Row>
            </Form.FormElement>
          )}
        </Form>
      )}
      <ConfirmPlanChange
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setSubmitting={setSubmitting}
        nextPlanName={nextPlanName}
        nextBillingInterval={nextBillingInterval}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

const tierPropTypes = PropTypes.oneOf([
  `FREE`,
  `STANDARD`,
  `PERFORMANCE`,
  `ENTERPRISE`,
])

const nextPlanNamePropTypes = PropTypes.oneOf([
  `Free`,
  `Professional`,
  `Business`,
  `Enterprise`,
  `Standard Builds - Performance Hosting`,
  `Standard Builds - Free Hosting`,
  `Standard Builds - Standard Hosting`,
  `Performance Builds - Performance Hosting`,
  `Performance Builds - Free Hosting`,
  `Performance Builds - Standard Hosting`,
  `Free Builds - Performance Hosting`,
  `Free Builds - Free Hosting`,
  `Free Builds - Standard Hosting`,
])
RequestForm.propTypes = {
  user: PropTypes.object,
  organizationId: PropTypes.string.isRequired,
  nextPlanInfo: PropTypes.shape({
    buildsTier: tierPropTypes,
    hostingTier: tierPropTypes,
    nextBuildsTier: tierPropTypes,
    nextHostingTier: tierPropTypes,
    planName: nextPlanNamePropTypes,
    planId: PropTypes.string,
  }),
}

export default RequestForm
